import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
  gql,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import {
  ControlPoint,
  ControlPointAssociation,
  deserialize,
  client as commonClient,
  ControlPointSafe,
  Status,
  Filter,
  NSRSType,
  NSRSWarning,
  PointType,
} from 'usmart-common';
import keycloakProvider from './../auth/keycloak';
import { Polygon } from '@turf/helpers';
import * as t from 'io-ts';
import { UUID } from 'io-ts-types';

export const serverEndpoint = import.meta.env.VITE_SERVER_BASE;

const uri = `${serverEndpoint}/graphql`;

const uploadLink = createUploadLink({
  uri,
  headers: { 'Apollo-Require-Preflight': 'true' },
});

const authLink = new ApolloLink((operation, forward) => {
  const op = operation;
  const token = keycloakProvider.getToken();
  op.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  // Removes all "__typename" properties used for client caching
  if (op.variables && !Object.hasOwn(op.variables, 'file')) {
    op.variables = JSON.parse(
      JSON.stringify(op.variables, (k, v) =>
        k === '__typename' ? undefined : v
      )
    );
  }
  return forward(op);
});

export const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache(),
  queryDeduplication: false,
});

export type VerticalExtendables = {
  accuracy: string[];
  accuracyNSRSVertical: string[];
  geoid: string[];
  units: string[];
  datum: string[];
  surveyComputationalMethod: string[];
};

export type HorizontalExtendables = {
  accuracyNSRSHorizontal: string[];
  datum: string[];
};

export type ControlExtendables = {
  armyInstallation: string[];
  condition: string[];
  fiuoType: string[];
  organization: {
    code: string;
    name: string;
    parent: string;
  }[];
  stability: {
    code: string;
    comments: string;
    description: string;
  }[];
};

export type AllExtendables = {
  controlPoint: ControlExtendables;
  document: string[];
  horizontal: HorizontalExtendables;
  state: {
    code: string;
    name: string;
  }[];
  vertical: VerticalExtendables;
};

export const getAssignedSubmittedPoints = (
  limit?: number,
  offset?: number,
  poly?: Polygon | undefined
): Promise<ControlPoint[]> =>
  client
    .query({
      fetchPolicy: 'no-cache',
      ...commonClient.controlPoint.getControlPoints(
        limit,
        offset,
        poly,
        Status.Submitted,
        true
      ),
    })
    .then(res => {
      if (res.error) {
        console.error(res.error);
        return [];
      }
      return res.data.ControlPoints.map(deserialize);
    });

export const SINGLE_UPLOAD_MUTATION = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

/**
 * @param id A Control point's ID
 * @returns Observable of the Control Point
 */
export const getControlPoint = (id: UUID): Promise<ControlPoint | undefined> =>
  client
    .query(commonClient.controlPoint.getControlPoint(id))
    .then(res => res.data.ControlPoint as ControlPointSafe)
    .then(cp => deserialize(cp));

export const getCoePids = (
  coePid: string,
  pointType: PointType,
  limit?: number
): Promise<{ coePid: string; id: UUID }[]> =>
  client
    .query({
      query: gql`
        query ControlPoint($filters: [Filter], $limit: Int) {
          ControlPoints(filters: $filters, limit: $limit) {
            id
            coePid
          }
        }
      `,
      variables: {
        limit,
        filters: [
          {
            key: 'coe_pid',
            op: 'like',
            val: coePid,
            valType: 'string',
          },
          {
            key: 'type',
            op: 'e',
            val: pointType,
            valType: 'string',
          },
        ],
      },
    })
    .then(res => {
      if (res.error) {
        throw res.error;
      } else {
        const pids: { coePid: string; id: UUID }[] = res.data.ControlPoints;
        return pids;
      }
    });

export const getControlPointsForProject = (
  id: UUID
): Promise<{ id: UUID; coePid: string; designation: string }[]> =>
  client.query(commonClient.project.projectControlPoints(id)).then(res => {
    if (res.error) {
      console.error(res.error);
      return [];
    }
    return res.data?.ProjectControlPoints;
  });

export const getControlPointsCount = (
  filters?: Filter[],
  polygon?: Polygon,
  status?: Status,
  assignedToUser: boolean = false
): Promise<number> =>
  client
    .query(
      commonClient.controlPoint.getControlPointsCount(
        polygon,
        status,
        assignedToUser,
        filters
      )
    )
    .then(res => {
      if (res.error) {
        console.error(res.error);
        return [];
      }
      return res.data?.ControlPointsCount;
    })
    .catch(err => {
      console.error(err);
      return 0;
    });

/**
 * @returns Promise<ControlPoints>
 */
export const getControlPoints = (
  limit?: number,
  offset?: number,
  filters?: Filter[],
  polygon?: Polygon,
  status?: Status,
  assignedToUser: boolean = false,
  signal?: AbortSignal
): Promise<ControlPoint[]> => {
  return client
    .query({
      ...commonClient.controlPoint.getControlPoints(
        limit,
        offset,
        polygon,
        status,
        assignedToUser,
        filters
      ),
      context: {
        fetchOptions: {
          signal,
        },
      },
    })
    .then(res => {
      if (res.error) {
        console.error(res.error);
        return [];
      }
      return res.data?.ControlPoints.map(deserialize);
    })
    .catch(err => {
      if (err.name === 'AbortError') {
        console.log('Promise Aborted');
      } else {
        console.log('Promise Rejected');
      }
      return [];
    });
};

export type User = {
  id: UUID;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  ident: string;
};

export const getUserInfo = async (userId: UUID): Promise<User | undefined> =>
  await client
    .query({
      query: gql`query GetUser {
    user(id: "${userId}") {
      id
      username
    }
  }`,
    })
    .then(res => {
      if (res.data) {
        return res.data.user as User;
      } else {
        return undefined;
      }
    });

const getControlPointAssociations = (localId: UUID) =>
  client.query(commonClient.association.getByLocal(localId)).then(res => {
    if (res.error) {
      throw res.error;
    } else {
      return res.data
        .ControlPointAssociationByLocal as ControlPointAssociation[];
    }
  });

export const getManyControlPointAssociations = (
  primaryIds: UUID[],
  localIds: UUID[]
) =>
  client
    .query(commonClient.association.getMany(primaryIds, localIds))
    .then(res => {
      if (res.error) {
        throw res.error;
      } else {
        return res.data
          .ControlPointAssociationsMany as ControlPointAssociation[];
      }
    });

const updateControlPointAssociations = (localId: UUID, primaryIds: UUID[]) =>
  client.mutate(
    commonClient.association.updateAssociations(localId, primaryIds)
  );

type Sync = {
  aoi: Polygon;
  lastSync: Date;
  history: boolean;
};

/**
 * @param aoi The search polygon for filtering control points
 * @param lastSync Control Points must be newer than lastSync
 * @param history Syncs control points and their history
 * @returns Observable of Control Points
 */
const sync = (s: Sync) =>
  client
    .query({
      query: gql`
        query Query($aoi: InputPolygon, $lastSync: String, $history: Boolean) {
          Sync(aoi: $aoi, lastSync: $lastSync, history: $history) {
            nsrsPid
            status
            id
            coePid
            fiuoType
            type
            designation
            stamping
            setting
            condition
            stability
            comments
            legacyData
            dateLineage
            userLineage
            dateObservedVertical
            dateObservedHorizontal
            dateRecovered
            userRecovered
            dateSubmitted
            userSubmitted
            localCoordinates
            vertical {
              surveyComputationalMethod
              accuracy
              epoch
              datum
              units
              orthometricHeight
              ellipsoidalHeight
            }
            owner
            isBoundaryMonument
            isMagnetic
            isGPSSuitable
            physicalAccess
            isObstructedNorth
            isObstructedSouth
            isObstructedEast
            isObstructedWest
            district
            armyInstallation
            state
            county
            trs
            nearestTown
            nearestHighway
            usgsQuad
            horizontal {
              surveyComputationalMethod
              accuracyNSRSHorizontal
              accuracy
              epoch
              datum
              hemisphereEW
              hemisphereNS
              latitude {
                degrees
                minutes
                seconds
              }
              longitude {
                degrees
                minutes
                seconds
              }
            }
            point {
              type
              coordinates
            }
          }
        }
      `,
      variables: {
        ...s,
        lastSync: s.lastSync.toISOString(),
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((res: any) => res.data.Sync as ControlPoint[]);

const createControlPoint = (cp: ControlPoint): Promise<ControlPoint> =>
  client
    .mutate(commonClient.controlPoint.createControlPoint(cp))
    .then(({ data }) => data.CreateControlPoint);

const createControlPointFromNSRS = (
  cp: ControlPoint,
  type: NSRSType,
  pid: string
): Promise<ControlPoint> =>
  client
    .mutate(commonClient.controlPoint.createControlPointFromNSRS(cp, type, pid))
    .then(({ data }) => data.CreateControlPointFromNSRS as ControlPoint);

const updateControlPoint = (cp: ControlPoint): Promise<ControlPoint> =>
  client
    .mutate(commonClient.controlPoint.updateControlPoint(cp))
    .then(({ data }) => data.UpdateControlPoint as ControlPoint);

const createControlPointAssociation = (cpa: ControlPointAssociation) =>
  client.mutate(commonClient.association.createAssociation(cpa));

export const serverHealthy = () =>
  client
    .query({
      query: gql`
        query HealthQuery {
          Health
        }
      `,
    })
    .then(res => res.data)
    .then((r: { Health: string }) => r.Health === 'ok')
    .catch(() => false);

const uploadImage = () =>
  client.query({
    query: gql`
      mutation singleUpload($file: Upload!, $attrs: InputUploadAttributes!) {
        singleUpload(file: $file, attrs: $attrs) {
          filename
        }
      }
    `,
  });

export const uploadImageOffline = () => uploadImage();

export const removeControlPoint = (id: UUID) =>
  client.mutate(commonClient.controlPoint.deleteControlPoint(id));

const approveControlPoint = (id: UUID) =>
  client.mutate(commonClient.controlPoint.approveControlPoint(id));

const rejectControlPoint = (id: UUID) =>
  client.mutate(commonClient.controlPoint.rejectControlPoint(id));

const getProjects = () => client.query(commonClient.project.getAll());

const getUserProjects = () =>
  client.query(commonClient.project.getAll('ALL', true, 500));

const getControlPointVersions = (
  coePid: string,
  versions: number = 100
): Promise<ControlPoint[]> =>
  client
    .query(commonClient.controlPoint.getControlPointVersions(coePid, versions))
    .then(res => res.data.ControlPointVersions as ControlPointSafe[])
    .then(cps => cps.map(cp => deserialize(cp)));

const checkWarning = (id: UUID): Promise<NSRSWarning[]> =>
  client
    .query(commonClient.controlPoint.checkWarning(id))
    .then(res => res.data.CheckWarning as NSRSWarning[]);

const getExtendableFields = (): Promise<AllExtendables> =>
  client
    .query({
      query: gql`
        query ExtendableFields {
          Fields {
            controlPoint {
              armyInstallation
              condition
              fiuoType
              organization {
                code
                name
                parent
              }
              stability {
                code
                comments
                description
              }
            }
            document
            horizontal {
              accuracyNSRSHorizontal
              datum
            }
            state {
              code
              name
            }
            vertical {
              accuracy
              accuracyNSRSVertical
              geoid
              units
              datum
              surveyComputationalMethod
            }
          }
        }
      `,
    })
    .then(d => d.data.Fields)
    .catch(err => {
      console.error(err);
      return [];
    });

export const actions = {
  createControlPoint,
  createControlPointFromNSRS,
  createControlPointAssociation,
  archiveControlPoint: createControlPoint,
  updateControlPoint,
  updateControlPointAssociations,
  checkWarning,
  uploadImage,
  removeControlPoint,
  sync,
  serverHealthy,
  getControlPoint,
  getControlPoints,
  getControlPointAssociations,
  getManyControlPointAssociations,
  getControlPointVersions,
  approveControlPoint,
  rejectControlPoint,
  getExtendableFields,
  getProjects,
  getUserProjects,
  getCoePids,
  getUserInfo,
  mutate: client.mutate,
};

export const actionsType = t.keyof(actions);
export declare type APIAction = t.TypeOf<typeof actionsType>;

export default actions;
